.contact-body{
  position: relative;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: radial-gradient(
    circle 975px at 2.6% 48.3%,
    rgba(0, 8, 120, 1) 0%,
    rgba(95, 184, 224, 1) 120.7%,
  );

  .contact-wave{
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    // transform: scale(1.3);
    width: 100vw;
    // transform: rotate(180deg);
    &-reverse{
      transform: rotate(180deg);
      top: 0;
    }
  }
  .contact-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-width: 90vw;
    h1{
      text-align: center;
    }
    label{
      font-weight: bold;
      padding-top: 10px;
    }
    input{
      height: 30px;
    }
    input, textarea{
      width: calc(100% - 20px);
      border-radius: 10px;
      border: 1px solid #000;
      padding: 0 10px;
    }
    textarea{
     padding: 10px;
    }
  }
}