.cta-body {
  height: 88dvh;
  background-image: radial-gradient(
    circle 975px at 2.6% 48.3%,
    rgba(0, 8, 120, 1) 0%,
    rgba(95, 184, 224, 1) 120.7%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    max-height: 80%;
    max-width: 90vw;
  }
  .cta-wave{
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    transform: scale(1.3);
    width: 87vw;
  }
}
