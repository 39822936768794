.nav-body {
  height: 12dvh;
  width: 100vw;
  // background: rgb(5, 0, 159);
  // background: linear-gradient(
  //   90deg,
  //   rgba(5, 0, 159, 1) 0%,
  //   rgba(64, 55, 255, 1) 47%,
  //   rgba(0, 212, 255, 1) 100%
  // );
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .nav-logo-body {
    height: 100%;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      height: 90%;
    }
  }
  .link-body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .link {
    // height: 80%;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    &:hover {
      color: lightgray;
      background-color: black;
    }

  }
  .contact-body {
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    color: white;
    .link {
      width: 100%;
    }
  }
}

@media (max-width: 770px) {
  .nav-body {
    // height: 10vh;
    .nav-logo-body {
      // width: 100px;
    }
    .link-body {
      display: none;
    }
  }
}
