@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-150px * 10));
  }
}

.reviews-body {
  width: 100%;
  height: 90vh;
  display: flex;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .review-carousel-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    // align-items: center;
  }
  .review-carousel {
    width: calc(500px * 10);
    height: 100%;
    display: flex;
    // justify-content: space-evenly;
    align-items: center;
    animation: scroll 30s linear infinite;

    .review-card {
      width: 500px;
      height: 300px;
      border-radius: 10px;
      background-color: lightgray;
      margin: 0 10px;
      padding: 10px;

      .review-card-header {
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin: 0 10px;
        h3 {
          font-size: 1.5rem;
          font-weight: 600;
        }
        p {
          font-size: 1.2rem;
          font-weight: 400;
        }
      }
      .review-card-body {
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .reviews-body {
      .review-carousel-container{
          width: 100%;
          overflow-x: scroll;
          justify-content: flex-start;
    }
    .review-carousel {
      width: calc(100vw * 10);
      height: 100%;
      display: flex;
      // justify-content: space-evenly;
      align-items: center;
      animation: none;

      .review-card {
        width: 70vw;
        height: fit-content;
        min-height: 300px;
        border-radius: 10px;
        background-color: lightgray;
        margin: 0 10px;
        padding: 10px;

        .review-card-header {
          width: 100%;
          display: flex;
          align-items: start;
          flex-direction: column;
          *{
            margin: 0;
          }
          h3 {
            font-size: 1.5rem;
            font-weight: 600;
          }
          p {
            font-size: 1.2rem;
            font-weight: 400;
          }
        }
        .review-card-body {
            margin-top: 30px;
        }
      }
    }
  }
}
