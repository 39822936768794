.about-body{
    height: 80dvh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .image-container{
        height: 100%;
        width: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .image-box{
            border-radius: 10px;
            background-color: gray;
            height: 80%;
            width: 80%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    .about-content-container{
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1{
            font-size: 3rem;
            font-weight: 600;
            margin-bottom: 20px;
        }
        p{
            width: 90%;
            font-size: 1.5rem;
            font-weight: 400;
            text-align: center;
        }
    }
}

@media (max-width: 770px) {
    .about-body{
        flex-direction: column;
        .image-container{
            width: 100%;
            height: 50%;
        }
        .about-content-container{
            width: 100%;
            height: 50%;
        }
    }
}